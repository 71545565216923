/* CSS Document */


/* 
*
*
[Table of contents]
*
*
*

  color / $white
  font / @import 
  general / html
  dropdown / .dropdown-custom.btn
  navbar / .navbar
  slider home / .jumbomain
  breadcumb / .breadcumb
  home / .subheading
  home / modal
  slickteam / .slickteam
  pricing / .pricelist
  testimony / .wraper-testimony
  footer / footer
  portofolio / AppContainer
  contact / .form-side
  service / #service
  blog / #blog
  media all / @media only screen and (max-width: 1199px)
  shop / #shop

*/

/*color*/
$white: #fff;
$gray: #bbb;
$black: #111;
$black_soft: #272727;
$black_col: #2e333c;
$black_more: #0f0f0f;
$color: #c8b16f;
$color_more: #c7ab5a;

/*font*/
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

/*general*/
body {
  font-family: "Poppins", 'Segoe UI', 'Ubuntu','Droid Sans', sans-serif;
  font-weight: 300;
  font-size: 15px;
  letter-spacing: .3px;
  color: $white;
  background: $black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#mainpreloader{
  .preloader{
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    z-index: 10000;
    -webkit-animation-duration: 1.6s;
    animation-duration: 1.6s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
     }
         @-webkit-keyframes fadeOut {
            0% {opacity: 1;}
            25% {opacity: 1;}
            75% {opacity: 1;}
            100% {opacity: 0;}
         }

         @keyframes fadeOut {
            0% {opacity: 1;}
            25% {opacity: 1;}
            75% {opacity: 1;}
            100% {opacity: 0;}
         }

         .fadeOut {
            -webkit-animation-name: fadeOut;
            animation-name: fadeOut;
         }
}
.mainpreloader {
  position: relative;
  animation: rotate 1s infinite linear;
  border: 3px solid rgba(255,255,255,.1);
  width: 40px;
  height: 40px;
  margin-right: -24px;
  border-radius: 999px;
}

/* styles.css */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.hidden {
  opacity: 0;
  transform: translateY(20px);
}

.fade-in {
  opacity: 1;
  transform: translateY(0);
  animation: fadeIn 1s forwards;
}


.fade-in {
  opacity: 0; /* Start with elements hidden */
  animation: fadeIn 1s forwards; /* Animate over 1 second and forwards to keep the final state */
}
.mainpreloader span {
  position: absolute;
  width: 40px;
  height: 40px;
  border: 3px solid transparent;
  border-top: 3px solid #c8b16f;
  top: -3px;
  left: -3px;
  border-radius: 999px;
}
@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
#routerhang{
  transform: unset !important;
  transform-origin: unset !important;
  width: 100%;
  height: 100%;
}
.br{
  display: block;
  white-space: pre-wrap;
}
section{
  padding: 50px 0;
}

.wraper{
  padding: 0;
  display: block;
  width: 100%;
  .wraperitem{
    width: 100%;
    height: 100%;
  }
}
.m-2-hor{
  padding: 0 7%;
  max-width: 1500px;
  margin: 0 auto !important;
}
.m-10-hor{
  display: flex;
  justify-content: space-between;
  padding-left: 10%;
  padding-right: 10%;
  max-width: 1500px;
  margin: 0 auto;
}
.white{
  color: $black;
  background: $white;
}
.black_more{
  background: $black_more;
}
.color{
  color: $color;
}
.btn{
  position: relative;
  overflow: hidden;
  font-size: 11pt;
  color: $white;
  background: none;
  border: 2px solid $color;
  border-radius: 0;
  padding: 10px 44px;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  z-index: 0;
  span{
    color: $white;
    position: relative;
    z-index: 1;
  }
  &::before{
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    background-image: linear-gradient(to left, $color, $color_more);
    opacity: 0;
    z-index: 1;
    transition: all 0.3s ease;
  }
  &:hover::before{
    opacity: 1;
    transition: all 0.3s ease;
  }
  &:hover {
    .shine{
    -webkit-animation: sheen .6s alternate;
    animation: sheen .6s alternate;
    } 
  }
}
.shine {
  content: '';
  position: absolute !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: -50px;
  background: -webkit-linear-gradient(top, transparent, rgba(255,255,255,0.2) 5%, transparent);
  background: linear-gradient(to bottom, transparent, rgba(255,255,255,0.2) 5%, transparent);
  -webkit-transform: rotateZ(60deg) translate(-1em, 5.5em);
  transform: rotateZ(60deg) translate(-1em, 5.5em);
}

@-webkit-keyframes sheen {
  0% {
     opacity: 1;
    -webkit-transform: rotateZ(60deg) translate(0, 6em);
    transform: rotateZ(60deg) translate(0, 6em);
  }
  90% {
    opacity: 1;
    -webkit-transform: rotateZ(60deg) translate(0, -12em);
    transform: rotateZ(60deg) translate(0, -12em);
  }
  100% {
    opacity: 0;
    -webkit-transform: rotateZ(60deg) translate(0, -12em);
    transform: rotateZ(60deg) translate(0, -12em);
  }
}

@keyframes sheen {
  0% {
    opacity: 1;
    -webkit-transform: rotateZ(60deg) translate(0, 6em);
    transform: rotateZ(60deg) translate(0, 6em);
  }
  90% {
    opacity: 1;
    -webkit-transform: rotateZ(60deg) translate(0, -12em);
    transform: rotateZ(60deg) translate(0, -12em);
  }
  100% {
    opacity: 0;
    -webkit-transform: rotateZ(60deg) translate(0, -12em);
    transform: rotateZ(60deg) translate(0, -12em);
  }
}
.text-gradient {
    -webkit-background-clip: text !important;
    -moz-background-clip: text !important;
    background-clip: text !important;
    -webkit-text-fill-color: transparent;
    display: inline-block;
    background: linear-gradient(to bottom, $color, $color_more);
}
/* dropdown */
  .dropdown-custom.btn{
    border: 0px;
    position: relative;
    top: -1px;
    overflow: unset !important;
    font-weight: 500;
    padding: 0 20px;
    background: none !important;
    &::before {
      content: '';
      position: absolute;
      top: -10px;
      left: 19px;
      width: max-content;
      height: auto;
      padding: 30px 40px;
      background: 0;
    }
    &::after{
      margin-left: 10px !important;
    }
    &:focus {
    box-shadow: none !important;
    }
    &:hover{
      color: $color;
    }
  }
  .dropdown-toggle::after{
    position: relative;
    top: 1px;
  }
  .item-dropdown{
    width: max-content;
    padding: 0;
    position: absolute;
    background: #1b1b1b;
    border-radius: 0px;
    inset: 40px auto auto 19px;
    padding: 5px 0;
    animation: smoothDrop .2s ease;
    -webkit-animation: smoothDrop .2s ease;
    z-index: 1;
    .dropdown{
      position: relative;
      text-align: center;
      a{
        color: rgba(255,255,255,.8);
        text-transform: none;
        font-weight: 400;
        letter-spacing: .6px;
        display: block;
        font-size: .78rem !important;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        padding: 12px 20px 12px 20px;
        min-width: 210px;
        width: 100%;
        text-align: left;
        &:hover{
          color: $color;
          background: 0;
        }
        &:last-child{
          border-bottom: none;
        }
      }
    }
  }
.imgslickz{
    width: 850px;
    height: 500px;
    object-fit: cover;
}
.centered {
    display: flex;
    align-items: center;
    justify-content: center;
}
/*navbar*/
.navbar {
    padding: 20px 0;
    position: absolute;
    top: 15px;
    left: 0;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    background: rgba(0,0,0,0);
    z-index: 999;
    .w-100-nav{
      margin: 0;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }
    a{
      position: relative;
      font-size: .85rem;
      font-weight: 500;
      letter-spacing: .6px;
      padding: 4px 20px;
      text-decoration: none !important;
      color: $white;
      outline: none;
      transition: all 0.3s ease;
      &:hover{
        color: $color;
        transition: all 0.3s ease;
      }
      &.active{
        color: $color !important;
        transition: all 0.3s ease;
        &:before{
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 0;
          left: 0;
          top: 0;
          background: 0;
          cursor: default;
        }
      }
    }
    .navbar-title {
    margin-right: auto;
    font-size: 150%;
    padding: 12px 20px;
    }
    .navbar-item {
        position: relative;
        display: inline-block;
        cursor: default;
        vertical-align: middle;
    }

    .logo {
      .navbar-item {
          position: relative;
          top: 0px;
          left: 0px;
          padding: 0;
          width: 200px;
          border-bottom: none;
          a {
              padding: 0;
              img {
                  width: 100%; 
                  height: auto;
              }
          }
      }
    }

    .nav-icon{
      display: none;
    }
    @media only screen and (max-width: 1199px) { 
      .m-2-hor {
          padding: 0px 2%;
      }
      .menu{
        padding: 15px 0 10px;
      }
      &.navbar {
        background: #1b1b1b;
        position: relative;
      }
      .w-100-nav{
        display: block;
      }
      .navbar-item {
        display: block;
        padding: 15px 0;
        border-bottom: 1px solid rgba(0,0,0,.2);
      }
      .nav-icon {
      position: absolute;
      top: 33px;
      right: 25px;
      display: block;
      width: 20px;
      height: 10px;
      padding-top: 13px;
      line-height: 0;
      cursor: pointer;
      background: none;
      border: none;
      padding: 0;
      z-index: 9999;
      }
      .bg-nav-icon{ 
      width:32px; 
      height:24px; 
      background:rgba(255,255,255,0);
      border-radius: 2px;
      -moz-border-radius: 2px;
      -webkit-border-radius: 2px;
      }
      #nav-icon span, .nav-icon span{ 
      display:none; 
      position:absolute; 
      top: 5px; 
      left: -40px;
      font-size: .62rem;
      font-weight: 600;
      text-transform: uppercase;
      }
      .menu-line {
      position: absolute;
      width: 100%;
      height: 2px;
      top: 0px;
      background-color:#fff;
      }
      .menu-line1 {
      position: absolute;
      width: 100%;
      height: 2px;
      top: 5px;
      background-color:#fff;
      }
      .menu-line2 {
      position: absolute;
      width: 100%;
      height: 2px;
      top: 10px;
      background-color:#fff;
      }
       &.sticky {
       position: absolute !important;
       }
     }
    &.sticky {
      position: fixed;
      top: 0;
      width: 100%;
      background: #1b1b1b;
      animation: smoothScroll .6s forwards;
      -webkit-animation: smoothScroll .6s forwards;
      }
      @keyframes smoothScroll {
        0% {
          transform: translateY(-300px);
          -webkit-transform: translateY(-300px);
        }
        100% {
          transform: translateY(0px);
          -webkit-transform: translateY(0px);
        }
      }
      @keyframes smoothDrop {
        0% {
          opacity: 0;
          transform: translateY(10px);
          -webkit-transform: translateY(10px);
        }
        100% {
          opacity: 1;
          transform: translateY(0px);
          -webkit-transform: translateY(0px);
        }
      }
}
/* disable click active */
.navbar-item {  
    pointer-events: auto;
}
.navbar-item a.active {
    pointer-events: none;
}

/* CSS */
.donate-button {
  align-items: center;
  background-color: $color_more;
  border: 2px solid #111;
  border-radius: 8px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  font-family: Inter,sans-serif;
  font-size: 15px;
  height: 45px;
  justify-content: center;
  line-height: 24px;
  max-width: 100%;
  padding: 0 25px;
  margin-left: 15px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.donate-button:after {
  background-color: #111;
  border-radius: 8px;
  content: "";
  display: block;
  height: 46px;
  left: 0;
  width: 100%;
  position: absolute;
  top: -2px;
  transform: translate(4px, 4px);
  transition: transform .2s ease-out;
  z-index: -1;
}

.donate-button:hover:after {
  transform: translate(0, 0);
}

.donate-button:active {
  background-color: #f2cd5d;
  outline: 0;
}

.donate-button:hover {
  outline: 0;
}


/* Campaign description & Donors */
.campaign-info-wrap {
  width: 100%;
  position: relative;
  padding: 3em;
  display: flex;
  background: $black; /* Adjusted for dark mode */
  color: $white; /* Text color for dark mode */
}

.campaign-story-sec {
  width: 50%;
  margin: 4em 2em 2em 11em;
  max-width: 960px;
}

.campaign-story-sec h3 {
  margin: 0em 0 0em -.5em;
  font-weight: bold;
  font-size: 3em;
  border-bottom: 1px solid $white; /* Adjusted for dark mode */
  width: 100%;
}

.green-shade {
  background: #1e1e1e; /* Adjusted for dark mode */
  margin: 0 0 3em -1.5em;
  padding-top: 0;
  height: 200px;
  width: 100%;
}

.wrap {
  padding: 1em 2em;
}

.wrap h5 {
  font-weight: bold;
  font-size: 1.5em;
  margin: 1em 0 1.5em 0;
  color: $color; /* Use your theme color */
}

.wrap p {
  font-size: 1.2em;
  color: $gray; /* Adjusted for readability */
}

.campaign-story h4 {
  font-weight: bold;
  font-size: 1.8em;
  margin: 1em 0;
  text-decoration: underline;
  color: $white; /* Text color for dark mode */
}

.campaign-story p {
  font-size: 1.3em;
  margin: 1em 0;
  color: $gray; /* Adjusted for readability */
}

.campaign-story img {
  width: 80%;
  height: auto;
}


// Stripe

.stripe-payment-form {
  background-color: #f4f4f4; /* Example background color */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  max-width: 400px;
  margin: 20px auto;
}

.stripe-payment-form form {
  display: flex;
  flex-direction: column;
}

.stripe-payment-form .StripeElement {
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
}

.stripe-payment-form button {
  background-color: #007bff; /* Example button color */
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.stripe-payment-form button:hover {
  background-color: #0056b3;
}



/* CSS */
.contact-button {
  align-items: center;
  background-color: $black_soft;
  border: 2px solid #111;
  border-radius: 8px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  font-family: Inter,sans-serif;
  font-size: 15px;
  height: 45px;
  justify-content: center;
  line-height: 24px;
  min-width: 120px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.contact-button:after {
  background-color: #111;
  border-radius: 8px;
  content: "";
  display: block;
  height: 46px;
  left: 0;
  width: 100%;
  position: absolute;
  top: -2px;
  transform: translate(4px, 4px);
  transition: transform .2s ease-out;
  z-index: -1;
}

.contact-button:hover:after {
  transform: translate(0, 0);
}

.contact-button:active {
  background-color: #454545;
  outline: 0;
}

.contact-button:hover {
  outline: 0;
}




/* slider */
.jumbomain{
  position: relative;
  padding: 0;
  margin: 0;
}
.slider-wrapper {
  position: relative;
  height: 100vh;
  overflow: hidden;
  .previousButton, .nextButton {
    opacity: 0;
    text-decoration: none;
    transition: all 0.3s ease;
  }
  &:hover{
    .previousButton, .nextButton {
      opacity: 1;
      transition: all 0.3s ease;
    }
  }
}
.slide{
  background-size: cover !important;
}
.slide::before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.1);
    bottom: 0;
    left: 0;
}
.previousButton, .nextButton {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    cursor: pointer;
    text-align: center;
    svg{
      display: none;
    }
}
.previousButton::before, .nextButton::before{
    content:"\f054";
    font-family: FontAwesome;
    font-size: 1.3rem;
    font-style: normal;
    font-weight: normal;
    color: #fff;
    opacity: .8;
}
.previousButton:hover, .nextButton:hover {
    opacity: 1;
}
.previousButton {
  left: 0;
  -webkit-transform: rotate(180deg) translateY(calc(50% + 0px));
          transform: rotate(180deg) translateY(calc(50% + 0px));
}
.previousButton{
  left: 4%;
}
.nextButton {
  right: 4%;
}
.slider-content {
  text-align: left;
}
.slider-content .inner {
  padding: 0 12%;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.slider-content .inner button{
  font-weight: 500;
  position: relative;
  overflow: hidden;
  font-size: 11pt;
  letter-spacing: 1px;
  color: $white;
  border: 2px solid $color;
  background: none ;
  border-radius: 0;
  padding: 0 44px;
  height: 45px;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  z-index: 0;
  span{
    position: relative;
    z-index: 1;
  }
  &::before{
    content: '';
    width: 180%;
    height: 180%;
    top: -100%;
    left: -100%;
    position: absolute;
    background-image: linear-gradient(to left, $color, $color_more);
    opacity: 0;
    z-index: 1;
    transition: all 0.3s ease;
  }
  &:hover::before{
    top: 0;
    left: 0;
    opacity: 1;
    transition: all 0.3s ease;
  }
  &:hover {
    .shine{
    -webkit-animation: sheen .6s alternate;
    animation: sheen .6s alternate;
    } 
  }
}
.slider-content .inner h1 {
  font-weight: 600;
  max-width: 840px;
  color: #fff;
  font-size: 53px;
}
.slider-content .inner p {
  color: #fff;
  font-size: 40px;
  line-height: 1.3;
  max-width: 640px;
  margin-bottom: 30px;
}

.slider-content section {
  position: absolute;
  bottom: 20px;
  left: 20px;
}
.slider-content section span {
  color: #fff;
}
.slider-content section span {
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  display: inline-block;
  text-align: left;
  line-height: 1.4;
  vertical-align: middle;
  margin-left: 10px;
}
.slider-content section img {
  width: 40px;
  height: 40px;
  border: solid 2px rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  vertical-align: middle;
}
.slider-content section span strong {
  color: #fff;
  font-size: 14px;
  display: block;
}
@media (max-height: 500px) {
  .slider-wrapper, .slide {
    height: calc(100vh - 75px);
  }    
}
@media (max-width: 640px) {
  .slider-wrapper, .slide {
    height: calc(100vh - 75px);
  }    
}
@media (max-height: 600px) {
  .slider-content .inner h1 {
    font-size: 32px;
  }    
}
@media (max-width: 640px) {
  .slider-content .inner h1 {
    font-size: 32px;
  }
  .slider-content .inner p{
    font-size: 21px;
  }
}
.slide h1 {
  transition: all 0.3s ease;
  -webkit-transform: translateY(-20px);
          transform: translateY(-20px);
  opacity: 0;
}
.slide button {
  transition: all 0.3s ease;
  -webkit-transform: translateY(20px);
          transform: translateY(20px);
  opacity: 0;
}
.slide p {
  transition: all 0.3s ease;
  -webkit-transform: translateY(20px);
          transform: translateY(20px);
  opacity: 0;
}
.slide section * {
  transition: all 0.3s ease;
}
.slide section img {
  -webkit-transform: translateX(-10px);
          transform: translateX(-10px);
  opacity: 0;
}
.slide section span {
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
  opacity: 0;
}
.slide section span strong {
  -webkit-transform: translateY(10px);
          transform: translateY(10px);
  opacity: 0;
}
.slide.animateIn.previous h1,
.slide.current h1,
.slide.animateIn.next h1,
.slide.animateIn.previous button,
.slide.current button,
.slide.animateIn.next button,
.slide.animateIn.previous p,
.slide.current p,
.slide.animateIn.next p,
.slide.animateIn.previous section *,
.slide.current section *,
.slide.animateIn.next section * {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  -webkit-transition-delay: .9s;
          transition-delay: .9s;
          opacity: 1;
}
.slide.animateIn.previous p,
.slide.animateIn.next p {
  -webkit-transition-delay: 1.1s;
          transition-delay: 1.1s;
}
.slide.animateIn.previous button,
.slide.animateIn.next button {
  -webkit-transition-delay: 1.3s;
          transition-delay: 1.3s;
}
.slide.animateIn.previous section img,
.slide.animateIn.next section img {
  -webkit-transition-delay: 1.3s;
          transition-delay: 1.3s;
}

.slide.animateIn.previous section span,
.slide.animateIn.next section span {
  -webkit-transition-delay: 1.4s;
          transition-delay: 1.4s;
}
.slide.animateIn.previous section span strong,
.slide.animateIn.next section span strong {
  -webkit-transition-delay: 1.5s;
          transition-delay: 1.5s;
}
.slide.animateOut h1 {
  -webkit-transition-delay: .3s;
          transition-delay: .3s;
}
.slide.animateOut p {
  -webkit-transition-delay: .2s;
          transition-delay: .2s;
}
.slide.animateOut section span {
  -webkit-transition-delay: .1s;
          transition-delay: .1s;
}
.slide.animateOut section span strong {
  -webkit-transition-delay: 0s;
          transition-delay: 0s;
}
/* breadcumb */
.breadcumb{
  border-radius: 0;
  margin: 0;
  padding: 0 0;
  background-size: cover;
  width: 100%;
  position: relative;
  &::before{
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(to bottom, $black, rgba(0,0,0,.1));
    opacity: .5;
  }
  .mainbreadcumb{
    padding: 320px 0 30px;
    h1{
      margin-bottom: 0;
      line-height: 1;
    }
    .list{
      position: relative;
      bottom: -20px;
      display: flex;
      justify-content: right;
      font-size: .86rem;
      font-weight: 400;
      letter-spacing: 4px;
      .dash{
        padding: 0 10px;
      }
      .link{
        color: $white;
        font-weight: 500;
        line-height: 1.3;
        letter-spacing: 1px;
        margin: 0;
        padding: 0;
        text-decoration: none;
        cursor: pointer;
        transition: all 0.3s ease;
        &:hover{
          color: $color;
          transition: all 0.3s ease;
        }
      }
    }
  }
  .homebread{
    width: 100%;
    padding: 180px 0 140px;
    .heading{
      line-height: 1.3;
      font-size: 3rem;
      font-weight: 700;
      margin-bottom: 15px;
    }
    .subheading{
      font-size: 2.1rem;
      margin-bottom: 0;
    }
    .content{
      max-width: 500px;
      
      margin-bottom: 0;
      margin-bottom: 30px;
    }
  }
  &.h-vh{
    height: 100vh;
    display: flex;
    align-items: center;
  }
}

.darken-overlay::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3); /* Adjust 0.3 to set the darkness level */
  pointer-events: none; /* So that the overlay does not interfere with interactions */
  z-index: 1;
}

/* home */
.subheading{
  font-size: 1.3rem;
  margin-bottom: 15px;
}
.heading{
  font-size: 2.3rem;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 15px;
  letter-spacing: 1px;
}
.content{
  margin: 0;  
}
.content a {
  text-decoration: none;
  color: inherit;
}

.content .socialicon i {
  color: inherit;
}
.col-feature{
  display: block;
  .sub-color{
    color: $color;
    font-size: 1.6rem;
    font-weight: 700;
  }
  .heading{
    font-size: 1.1rem;
    font-weight: 500;
    margin: 5px 0;
  }
  .content{
    font-size: 1rem;
  }
}
.border-radius{
  border-radius: 0px;
  box-shadow: 0 10px 12px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
  transition: all 0.3s ease;
  &:hover{
    box-shadow: none;
    transition: all 0.3s ease;
  }
}
/* icon scroll */
.icon-scroll-wraper{
  position: absolute;
  width: 100%;
  bottom: 5vh;
  left: 0;
}
.icon-scroll{
    position: relative;
    margin: 0 auto;
}
.icon-scroll:before {
    position: absolute;
}
.icon-scroll {
    width: 26px;
    box-shadow: inset 0 0 0 1px rgba(255,255,255,.3);
    z-index: 1;
    border-radius: 25px;
    height: 38px;
}
.icon-scroll:before {
    content: '';
    width: 3px;
    height: 6px;
    background: rgba(255,255,255,.6);
    left: 12px;
    top: 8px;
    border-radius: 4px;
    -webkit-animation-duration: 1.5s;
          animation-duration: 1.5s;
    -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
    -webkit-animation-name: scroll;
          animation-name: scroll;
}
@-webkit-keyframes scroll {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        -webkit-transform: translateY(20px);
        transform: translateY(20px);
    }
}
@keyframes scroll {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        -webkit-transform: translateY(20px);
        transform: translateY(20px);
    }
}
.features{
  position: relative;
  width: 100%;
  height: 100%;
  padding: 60px;
  overflow: hidden;
  .bg{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    
    &::before {  // Adding the pseudo-element
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.6);  // This is the black overlay with 60% opacity
      z-index: 0;  // This ensures the overlay is on top of the image
    }

    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 0;  // This ensures the image is below the overlay
    }
  }
  .content{
    position: relative;
    text-align: center;
    .heading{
      color: $color;
      font-weight: 500;
      font-size: 1.2rem;
      margin-bottom: 10px;
    }
    .con-text{
      font-size: .9rem;
    }
    .link{
      width: max-content;
      font-weight: 500;
      position: relative;
      overflow: hidden;
      font-size: 11pt;
      letter-spacing: 1px;
      color: #fff;
      border: 2px solid $color;
      background: none;
      border-radius: 0;
      padding: 0 44px;
      height: 45px;
      line-height: 40px;
      margin: 20px auto 0;
      cursor: pointer;
      transition: all 0.3s ease;
      &:hover{
        background: $color;
        border: 2px solid rgba(0,0,0,0);
        transition: all 0.3s ease;
        .shine{
        -webkit-animation: sheen .6s alternate;
        animation: sheen .6s alternate;
        } 
      }
    }
  }
}
/*slickteam*/
.slickteam{
  width: 100%;
  position: relative;
  padding: 30px 0 0;
  .slick-prev{
      opacity: 0;
      left: -30px;
      z-index: 1;
      transition: all 0.3s ease;
      &::before {
        
        content: "\f053" !important;
        font-family: FontAwesome !important;
        font-style: normal;
        font-weight: normal;
        color: #fff;
    }
  }
  .slick-next{
    right: -30px;
    opacity: 0;
    transition: all 0.3s ease;
    &::before {
      
      content: "\f054" !important;
      font-family: FontAwesome !important;
      font-style: normal;
      font-weight: normal;
      color: #fff;
    }
  }
  .slick-slide{
    position: relative;
    div{
      margin: 0 5px;
      position: relative;
      div{
        margin: 0 5px;
        .itm{
          margin: 0;
          position: relative;
          height: 100%;
          outline: none;
          overflow: hidden;
          .bg{
            position: relative;
            overflow: hidden;
            border-radius: 4px;
            &:after {
              content: '';
              display: block;
              padding-bottom: 100%;
            }
            img{
              width: 100%;
              height: auto;
              object-fit: cover;
            }
          }
        }
        .desc{
          position: absolute;
          bottom: 15px;
          left: 15px;
          text-align: left;
          .name{
            font-weight: 500;
            font-size: 1.3rem;
            letter-spacing: 1px;
          }
          .title{
            font-weight: 400;
            font-size: .9rem;
            line-height: 1.5;
            transition: all 0.3s ease;
          }
        }
        .icon{
            width: 100%;
            position: absolute;
            text-align: right;
            right: 15px;
            bottom: -30px;
            transition: all 0.3s ease;
            span{
              width: 100%;
              height: auto;
              color: inherit;
              padding: 10px;
              i{
                
                transition: all .3s ease;
              }
              &:hover{
                i{
                transform: scale(1.3);
                }
              }
            }
        }
        &:hover{
          cursor: pointer;
          .bg:before{
            opacity: .8;
            background: linear-gradient(to top, #000, rgba(0,0,0,.8));
            transition: all 0.3s ease;
          }
          .icon{
            bottom: 15px;
            transition: all 0.3s ease;
          }
          .title{
            line-height: .9;
            transition: all 0.3s ease;
          }
          .hover-text {
            opacity: 1; // make it fully visible on hover
          }
        }
        .hover-text {
          font-family: 'Montserrat', sans-serif;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-color: rgba(0, 0, 0, 0.6);
          color: #fff;
          padding: 10px;
          border-radius: 5px;
          opacity: 0;
          z-index: 2;
          transition: opacity 0.3s ease; 
        }
      }
    }
  &.slick-col{
      display: block;
      overflow: hidden;
      div .itm .bg img{
        min-height: 300px !important;
      }
    }
  }

  &:hover{
    .slick-prev, .slick-next{
      opacity: 1;
      transition: all 0.3s ease;
    }
  }
}

.slick-slide {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2); /* Adjust the opacity (0.5) to make it darker or lighter */
  z-index: 1; /* Ensure this is above the image but below the text */
}

/* modal */

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 999;
}

.popup {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  bottom: 0;
  backdrop-filter: blur(10px);
  right: 0;
  z-index: 999;
  overflow: hiden;
  transition: .64s ease-in-out;
  opacity: 1;
  visibility: visible;
  &-inner {
    position: relative;
    bottom: -100vw;
    right: -100vh;
    display: flex;
    align-items: center;
    max-width: 800px;
    max-height: 600px;
    width: 60%;
    height: 80%;
    background-color: #000000;
    transform: rotate(32deg);
    transition: .64s ease-in-out;
  }
  &__photo {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
  }
  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 60%;
    height: 100%;
    padding: 4rem;
    h1 {
      font-size: 2rem;
      font-weight: 600;
      margin-bottom: 2rem;
      text-transform: uppercase;
      color: #ffffff;
    }
    p {
      font-size: .875rem;
      color: #ffffff;
      line-height: 1.5;
    }
  }
  .popup-inner {
    bottom: 0;
    right: 0;
    transform: rotate(0);
  }
  &__close {
    position: absolute;
    right: -1rem;
    top: -1rem;
    width: 3rem;
    height: 3rem;
    font-size: .875rem;
    font-weight: 300;
    border-radius: 100%;
    background-color: #0A0A0A;
    z-index: 4;
    color: #fff;
    line-height: 3rem;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
  }
}

/* pricing */
.pricelist {
    display: block;
    border: 8px solid rgba(255,255,255,.1);
    padding: 20px 10px;
    margin: 30px auto 20px;
    text-align: center;
    transition: all 0.3s ease;
    .heading{
      font-size: 1.75rem;
      font-weight: 500;
      border-bottom: 1px solid rgba(255,255,255,.1);
      padding: 15px 0 30px;
    }
    .bigprice{
      font-size: 3.6rem;
      font-weight: 700;
      line-height: 1;
      padding: 10px 0 20px;
      border-bottom: 1px solid rgba(255,255,255,.1);
      small{
        font-size: .8rem;
        font-weight: 400;
        opacity: .8;
      }
    }
    .list{
      list-style: none;
      padding: 0px;
      text-align: left;
      li{
        padding: 10px 0;
        border-bottom: 1px solid rgba(255,255,255,.1);
        i{
          position: relative;
          top: -2px;
          font-size: .8rem;
          padding-right: 2px;
        }
      }
    }
    &:hover{
      border: 8px solid $color;
      transition: all 0.3s ease;
    }
}

/* banner */

.banner {
  background: $color;
}

.banner__content {
  padding: 16px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  max-width: 700px;
  
}

.banner__text {
  font-family: "Quicksand", sans-serif;
}

.banner__close {
  background: none;
  border: none;
  cursor: pointer;
}

.banner__text,
.banner__close > span {
  color: #ffffff;
}


/*testimony*/
.wraper-testimony{
  background-size: cover;
  overflow: hidden;
  width: 100%;
  position: relative;
  padding: 110px 0;
  &::before{
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(to left, $color, $color_more);
    opacity: .95;
  }
}
.testimony{
  width: 100%;
    .slick-prev{
        opacity: 0;
        left: -30px;
        z-index: 1;
        transition: all 0.3s ease;
        &::before {
          
          content: "\f053" !important;
          font-family: FontAwesome !important;
          font-style: normal;
          font-weight: normal;
          color: #fff;
      }
    }
    .slick-next{
      right: -30px;
      opacity: 0;
      transition: all 0.3s ease;
      &::before {
        
        content: "\f054" !important;
        font-family: FontAwesome !important;
        font-style: normal;
        font-weight: normal;
        color: #fff;
      }
    }
    .slick-slide{
      position: relative;
      div{
        margin: 0 5px;
      }
    }
    &:hover{
      .slick-prev, .slick-next{
        opacity: 1;
        transition: all 0.3s ease;
      }
    }
    .blockquote{
      font-size: .98rem;
      text-align: left;
      margin: 15px 30px 30px;
      display: flex;
      .fa {
        font-size: 1.3rem;
        display: inline-block;
        margin: 5px 15px 0 0;
        color: $white;
        height: max-content;
      }
      p{
        
        line-height: 1.8;
        display: inline-block;
      }
      .bytesti {
        font-size: .9rem;
        font-weight: 400;
      }
    }
    .slick-dots {
      bottom: -40px;
      li.slick-active button::before {
        opacity: .75;
        color: $white;
      }
      li button::before {
        color: $white;
      }
    }
}
/* footer */
footer{
  position: relative;
  padding: 75px 0 0 ;
  padding-right: 0px !important;
  padding-left: 0px !important;
  overflow: hidden;
  .bg-footer{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: .3;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .footer-col{
    padding-right: 15px;
    padding-left: 15px;
    margin: 15px 0;
    .heading{
      font-weight: 500;
      font-size: 1.1rem;
      margin-bottom: 10px;
    }
    .content{
      line-height: 1.6;
      letter-spacing: .6px;
      margin-bottom: 10px;
      .link{
        font-size: .96rem;
        width: max-content;
        letter-spacing: 0;
        padding: 0 0 5px;
        margin-bottom: 5px;
        cursor: pointer;
        transition: all 0.3s ease;
        border-bottom: 1px solid rgba(200,177,111,.8);
        &:hover{
          opacity: .6;
          transition: all 0.3s ease;
        }
      }
      .socialicon{
        position: relative;
        overflow: hidden;
        display: inline-flex; /* Change to inline-flex for centering */
        align-items: center; /* Center vertically */
        justify-content: center; /* Center horizontally */
        padding: 10px 14px;
        background: #000; /* Replace $black with actual color */
        margin-right: 10px;
        text-align: center;
        border-radius: 4px;
        cursor: pointer;
        border: 1px solid rgba(200,177,111,.8);
        transition: all 0.3s ease;
        i{
          font-size: 1rem;
        }
        &:hover{
          background: $color;
          transition: all 0.3s ease;
          .shine{
          z-index: 1;
          -webkit-animation: sheen 2s alternate;
          animation: sheen 2s alternate;
          } 
        }
      }
    }
    .link-call-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
    
    .link-call {
      font-weight: 300;
      display: inline-block;
      color: $color;
      font-size: .8rem;
      width: max-content;
      letter-spacing: 1px;
      padding: 0 0 5px;
      cursor: pointer;
    }
    
    .link-call-ex {
      transition: all 0.3s ease;
    
      &:hover {
        opacity: .6;
        transition: all 0.3s ease;
      }
    }
    
    @media (max-width: 576px) {
      .link-call {
        width: 100%;
        text-align: center;
        padding: 5px 0;
      }
    
      .link-call-container {
        flex-direction: column;
      }
    }
    
  }
}
.subfooter{
  width: 100%;
  margin-top: 90px;
  padding: 30px 15px;
  background: rgba(0,0,0,.6);
  .content{
    font-size: .8rem;
    opacity: 1;
    letter-spacing: .3px;
    margin: 0;
    color: #eee;
  }
  .content-right{
    text-align: right;
    font-size: .9rem;
    opacity: .8;
    letter-spacing: .3px;
    margin: 0;
    i{
      padding: 0 5px;
    }
  }
  @media only screen and (max-width: 768px) { 
    .content-right{
      text-align: left;
      margin-top: 5px;
    }
  }
}



.insta-feed {
  // center div
  display: flex;
  margin: 0 auto;
  padding: 0;
  border: 0;
  max-width: 600px;
  overflow: hidden;
  margin-top: 20px;
  margin-bottom: 20px;
  justify-content: center;
  @media only screen and (max-width: 768px) { 
    max-width: 350px;
  }
}

.instagram-media {
  display: block;
  margin: 0 auto;
  padding: 0;
  border: 0;
  min-height: 500px;
  overflow: hidden;
  // work on phone
  @media only screen and (max-width: 768px) { 
    min-height: 300px;
    min-width: 300px;
  }
}



.instagram-link {
  display: block;
  margin: 0 auto;
  padding: 10px 10px;
  max-width: 400px;
  border: 2px solid #c7ab5a;
  border-radius: 5px;
  background-color: $black_soft;
  margin-top: 20px;
  margin-bottom: 20px;
  transition: all 0.3s ease;
  color: #c7ab5a;
  text-decoration: none;
  // remove underline and color of a tag
  &:hover {
    color: #fff;
    background-color: #c7ab5a;
    transition: all 0.3s ease;
  }
  font-size: 18px;
  font-weight: bold;
  // work on phone

}


.embed-container {
  border: 3px solid #c7ab5a;
  border-radius: 5px;
  padding: 20px;
  background-color: $black_soft;
  margin: 20px 0;
}

.features img {
  width: 80%;
  height: auto;
  object-fit: cover;
}

.features .content {
  padding: 20px;
}

.features .link {
  display: inline-block;
  margin-top: 5px;
  color: #c7ab5a;
  text-decoration: none;
  font-weight: bold;
  border-bottom: 2px solid #c7ab5a;
}

.features .link:hover {
  color: #fff;
  background-color: #c7ab5a;
  transition: all 0.3s ease;
}

.features .link .shine {
  display: none;
}



.footer-col .contact-info .info-item {
  display: flex;
  align-items: center;
  margin: 5px 0;
  font-size: 14px;
}

.footer-col .contact-info .info-item i {
  margin-right: 10px;
  color: #c8b16f;
}

.footer-col .content .link a {
  color: #c8b16f;
  text-decoration: none;
}

.footer-col .content .link a:hover {
  text-decoration: underline;
}



.footer-col .socialicon {
  width: 40px;
  height: 40px;
  background: #444;
  color: #fff;
  transition: background 0.3s ease;
}


.footer-col .socialicon:hover {
  background: #c8b16f;
}

.subfooter {
  background: #222;
  padding: 10px 0;
  color: #fff;
  text-align: center;
  font-size: 14px;
}

.subfooter .content {
  display: flex;
  align-items: center;
  justify-content: center;
}

/** portofolio **/
.AppContainer{
  color: $white;
  position: relative;
  z-index: 99;
  height: 100%;
  width: 100%;
  background: none;
  .gallery-container{
    .react-photo-gallery--gallery{
      color: $white;
    }
    justify-content: center;
    flex-wrap: wrap;
  }
}
.lightbox-portal{
  position: relative;
  z-index: 999;
  h4{
    font-weight: 700;
  }
}
.btnLright{
  color: $white;
  font-size: 21px !important;
  right: 10px !important;
}
.btnLleft{
  color: $white;
  font-size: 21px !important;
  left: 10px !important;
}
.closeL{
  font-size: 21px !important;
  position: absolute;
  top: 10px;
  right: 10px;
}
.MainGimg{
  transform: scale(1);
  -webkit-transition: all .3s cubic-bezier(.645,.045,.355,1);
  transition: all .3s cubic-bezier(.645,.045,.355,1);
  .overlayCap{
    font-size: 1.5rem;
    width: 100%;
    bottom: 15px;
    padding-left: 20px;
    font-weight: 600;
    left: 0;
    z-index: 1;
  }
  &:before{
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: -webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(0, 0, 0, 0.5)));
    bottom: 0;
    left: 0;
    z-index: 1;
  }
  &:hover{
    transform: scale(1.01);
    box-shadow: 0 10px 12px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    z-index: 2;
  }
  .overlayDesc {
    position: absolute;
    bottom: 50px;
    left: 0;
    width: 100%;
    padding: 10px 20px;
    z-index: 1;
    color:rgba(255, 255, 255, 0.75);
    font-size: 0.8rem;
  }
}
/** contact **/
.form-side{
  padding: 30px 40px;
  background: $black;
  border-radius: 4px;
}
.text-side{
  padding: 15px 0;
  margin-top: 15px;
  .address{
    padding-top:0px;
    line-height: 1.6;
    .heading{
      font-size: 1.4rem;
      font-weight: 500;
      letter-spacing: 1px;
    }
    .list{
      position: relative;
      max-width: 320px;
      display: block;
      padding: 5px 0 15px;
      padding-left: 40px;
      i{
        position: absolute;
        top: 5px;
        left: 5px;
        font-size: 1.3rem;
        display: inline-block;
      }
      a{
        color: $white;
        text-decoration: none;
        transition: all 0.3s ease;
        &:hover{
          color: $color;
          transition: all 0.3s ease;
        }
      }
    }
  }
}
.formcontact{
  display: block;
  width: 100%;
  label{
    display: block;
    width: 100%;
    margin-bottom: 5px;
  }
  input{
      width: 100%;
      font-size: .9rem;
      width: 100%;
      padding: 8px 10px;
      border: 1px solid #ebebeb;
      margin-bottom: 15px;
    }
    textarea{
      font-size: .9rem;
      width: 100%;
      padding: 8px 10px;
      border: 1px solid #ebebeb;
      margin-bottom: 15px;
    }
    .msg-error{
      font-size: .87rem;
      opacity: .8;
      padding: 5px 0;
    }
  button{
  position: relative;
  overflow: hidden;
  font-size: 11pt;
  color: $white;
  background: none;
  border: 2px solid $color;
  border-radius: 0;
  padding: 8px 50px;
  margin-top: 15px;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  z-index: 0;
  span{
    color: $white;
    position: relative;
    z-index: 3;
  }
  &::before{
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    background-image: linear-gradient(to left, $color, $color_more);
    opacity: 0;
    z-index: 1;
    transition: all 0.3s ease;
  }
  &:hover::before{
    opacity: 1;
    transition: all 0.3s ease;
  }
  &:hover {
    .shine{
    -webkit-animation: sheen 1s alternate;
    animation: sheen 1s alternate;
    } 
  }
}
}
#success, #failed{
  &.show{
    display: block !important;
  }
  &.hide{
    display: none;
  }
}
#buttonsent.show{
  pointer-events: none;
}
#service, #events{
  display: block;
  position: relative;
  .left{
    display: block;
    position: relative;
    .mainimg{
      width: 100%;
      height: auto;
      position: relative;
      margin-bottom: 15px;
      img{
        width: 100%;
        height: auto;
        object-fit: cover;
      }
    }
    .content{
      .title{
        font-size: 1.7rem;
        font-weight: 500;
        letter-spacing: .6px;
        text-transform: uppercase;
        padding: 15px 0; 
      }
      .desc{
        letter-spacing: .3px;
        blockquote{
          font-size: 95%;
          padding: 25px;
          margin: 1.8rem 0 1.5rem;
          font-style: italic;
          background: #333;
          border-left: 4px solid $color;
        }
      }
    }
  }
  .right{
    .services-list{
      padding: 0;
      margin: 0;
      list-style: none;
      li{
        position: relative;
        a{
          font-weight: 500;
          font-size: 14px;
          letter-spacing: .6px;
          display: block;
          padding: 14px 20px;
          border-bottom: 1px solid rgba(255,255,255,.1);
          background: #333;
          transition: all 0.3s ease;
          cursor: pointer;
          color: $white;
          text-decoration: none;
          &:hover{
          background: #555;
          transition: all 0.3s ease;
          }
          &.active{
            background: #555;
          }
        }
      }
    }
  }
  .bannerservice{
    position: relative;
    padding: 50px 20px;
    margin-top: 30px;
    text-align: center;
    .imgbg{
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .contb{
      position: relative;
      z-index: 2;
      .headingb{
        font-size: 1.7rem;
        font-weight: 500;
      }
      .descb{
        font-size: 1rem;
      }
      .btn{
        padding: 10px 20px;
        text-align: center;
        margin-top: 20px;
      }
    }
    &:before{
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0,0,0,.3);
      z-index: 1;
    }
  }
}
#events{
  .recent-post li {
    list-style-type: none; 
    border: 2px solid $color_more; /* Add a light border */
    margin-bottom: 20px;
    background-color: $black;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Add a subtle shadow */
    transition: box-shadow 0.3s ease-in-out; /* Smooth transition for hover effect */
    display: flex;
    align-items: center;
    justify-content: center;

  }
  
  .recent-post li:hover {
    box-shadow: 0 5px 15px rgba(0,0,0,0.2); /* Darker shadow on hover */
  }
  
  .recent-post .img {
    width: 100%; /* Ensure the image covers the width of the box */
    overflow: hidden; /* Hide parts of the image that don't fit */
  }
  
  .recent-post .img img {
    width: 100%; /* Make images stretch to cover the width */
    height: auto; /* Adjust height automatically */
    transition: transform 0.3s ease-in-out; /* Smooth transition for image transform */
  }
  
  .recent-post .img:hover img {
    transform: scale(1.05); /* Slightly zoom in on hover */
  }
  
  .recent-post .contblog {
    padding: 15px; /* Add padding around the content for spacing */
  }
  
  .recent-post .title {
    margin-top: 0; /* Remove any default margin from the top of titles */
    font-size: 25px; /* Specify font size for consistency */
  }
  
  .recent-post .datetime, .recent-post .descblog {
    margin-top: 10px; /* Add some space above date/time and description */
  }
  
  .buttonEvent {
    margin-top: 15px; /* Add some space above the button */

  }
  h3{
    width: max-content;
    font-size: 1.6rem;
    margin-bottom: 30px;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(200, 177, 111, 0.6);
    // allign
    text-align: center;
  }
}
#newevents{
  .recent-post{
    list-style: none;
    color: $white;
    padding: 5px;
    margin: 0 0 45px;
    a.recent{
      color: $white;
      text-decoration: none;
      display: flex;
      margin-top: 15px;
      margin-bottom: 15px;
      .img{
        width: 50%;
        height: 20%;
        overflow: hidden;
        margin: 10px;
        border: 3px solid $color;
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .contblog{
        padding: 9px;
        width: 70%;
        text-align: center;
        .title{
          font-size: 1.1rem;
          font-weight: 500;
          text-decoration:underline;
        }
        .datetime{
          font-size: .9rem;
          opacity: .8;
          color: $color;
          margin-top: 5px;
          margin-bottom: 5px;
        }
        .descblog{
          font-size: .9rem;
        }
      }
      @media (min-width: 768px){
        .contblog {
          padding-top: 50px;
        }
      }
      @media (max-width: 768px) {
        flex-direction: column;
        
        .img {
          width: 80%;
          height: auto;
          margin: 0 auto;
          margin-bottom: 10px;
          order: -1;
          display: flex;
          justify-content: center;

          img {
            max-width: 100%;
            height: auto;
          }
        }
        .contblog {
          width: 100%;
        }
        @media (min-width: 768px) {
          .contblog {
            padding-top: 50px;
          }
        }
      }
    }
  }
}

.buttonEvent {
  position: relative;
  background: #444;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  border: none;
  letter-spacing: 0.1rem;
  font-size: 1rem;
  padding: 1rem 3rem;
  transition: 0.2s;
}

.buttonEvent:hover {
  letter-spacing: 0.2rem;
  padding: 1.1rem 3.1rem;
  background: $color_more;
  color: $color_more;
  animation: box 3s infinite;
}

.buttonEvent::before {
  content: "";
  position: absolute;
  inset: 2px;
  background: #272822;
}

.buttonEvent span {
  position: relative;
  z-index: 1;
}
.buttonEvent i {
  position: absolute;
  inset: 0;
  display: block;
}

.buttonEvent i::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 2px;
  left: 80%;
  top: -2px;
  border: 2px solid $color_more;
  background: #272822;
  transition: 0.2s;
}

.buttonEvent:hover i::before {
  width: 15px;
  left: 20%;
  animation: move 3s infinite;
}

.buttonEvent i::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 2px;
  left: 20%;
  bottom: -2px;
  border: 2px solid $color_more;
  background: #272822;
  transition: 0.2s;
}

.buttonEvent:hover i::after {
  width: 15px;
  left: 80%;
  animation: move 3s infinite;
}

@keyframes move {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes box {
  0% {
    box-shadow: #27272c;
  }
  50% {
    box-shadow: 0 0 25px $color_more;
  }
  100% {
    box-shadow: #27272c;
  }
}


/*** media all ***/
@media only screen and (max-width: 1199px) {
  .item-dropdown{
    position: relative;
    width: max-content;
    inset: unset;
    padding-top: 10px;
    border: 0;
    background: none;
    .dropdown{
      a{
        padding: 10px 10px 10px 10px;
        width: max-content;
        text-align: left;
        left: 20px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      }
    }
  }
  .navbar{
    top: 0;
  }
}
@media only screen and (max-width: 1024px) {
  .m-10-hor {
    padding-left: 2%;
    padding-right: 2%;
  }
}
@media only screen and (max-width: 768px) {
  .breadcumb .mainbreadcumb .list{
    justify-content: left;
    text-align: left;
    margin-bottom: 30px;
  }
  .previousButton, .nextButton {
    opacity: 1 !important;
  }
}
@media only screen and (max-width: 600px) {

}
@media only screen and (max-width: 420px) {

}
@media only screen and (max-width: 378px) {

}

// Aya box
.box {
  background-color: transparent;
  border-radius: 3px;
  color: #fff;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 350px;
  height: 250px;
  transform-style: preserve-3d;
  perspective: 2000px;
  transition: .4s;
  text-align: center;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    border-top: 20px solid #fff;
    border-left: 20px solid #fff;
    box-sizing: border-box;
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-bottom: 20px solid #fff;
    border-right: 20px solid #fff;
    box-sizing: border-box;
  }
  .fas {
    font-size: 25px;
    height: 50px;
    width: 50px;
    line-height: 50px !important;
    background-color: #fff;
    color: $color;
  }
  .fa2 {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
  }
  .text {
    position: absolute;
    top: 30px;
    left: -30px;
    width: calc(100% + 60px);
    height: calc(100% - 60px);
    background-color: $color;
    border-radius: 3px;
    transition: .4s;
    .fa1 {
      position: absolute;
      top: 0;
      left: 0;
    }
    div {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      text-align: center;
      width: 100%;
      padding: 30px 60px;
      line-height: 1.5;
      box-sizing: border-box;
      h3 {
        font-size: 30px;
        margin-bottom: 5px;
      }
      p {
        font-size: 15px;
      }
    }
  }
  &:hover {
    .text {
      transform: rotateY(20deg) skewY(-3deg)
    }
    transform: translate(-50%, -50%) rotateY(-20deg) skewY(3deg)
  }
}

.wrap-donorbox_hadith {
  display: flex;
  flex-direction: column; 
  justify-content: center; 
  align-items: center; 
  height: 100%; 
  position: relative; 
  padding: 1rem 1.5rem;
  overflow: hidden; /* Hide the overflow to clip the image */
  z-index: 0;
}

.wrap-donorbox_hadith::before {
  content: ''; 
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  pointer-events: none;
  z-index: 1;
}

.wrap-donorbox_hadith::after {
  content: ''; 
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: -10%; /* Move the image to the left to hide 50% of it */
  width: 500%; /* Double the width to compensate for the left movement */
  background-image: url(https://m.media-amazon.com/images/I/71nvrnFKJ5L.jpg);
  background-size: contain;
  pointer-events: none;
  z-index: 0;
}


// Popup

/* Add this CSS to your HomePopup.css file */

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup {
  background-color: $black_soft;
  max-width: 600px;
  width: 75%;
  height: 400px;
  margin: auto;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
}

#close {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 20px;
  border: none;
  background: none;
  cursor: pointer;
  color: #666; 
}

#close:hover {
  color: #fff;
}

.popup h2 {
  color: #fff;
  margin-bottom: 20px;
}

.popup p {
  color: #fff;
  line-height: 1.6;
  margin-bottom: 25px;
}

.popup a:hover {
  background-color: #0056b3; /* Darker shade of blue on hover */
}

// on phone
@media (max-width: 600px) {
  .popup {
    transform: translate(-5px);
    width: 80%;
    height: 450px;
  }
}


.text-size-medium {
  font-size: 1.5rem;
  position: relative;
  text-decoration: underline; 
  font-weight: bold;
  z-index: 2;
}

.text-size-small {
  font-size: 1.4rem;
  position: relative; 
  font-weight:300;
  z-index: 2;
  font-weight: bolder;
  text-align: center;
}

.text-block {
  color: #fff;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-family: arabic;
  font-weight: bolder;
  text-align: center;
  font-size: 2rem;
  position: relative;
  z-index: 2;
  text-align: center;

}


/* Shop */

.container-shop {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 20px;
  justify-content: center;
  align-items: start; 
  max-width: 1200px; 
  margin: auto;
 
}


.card-shop {
  border-radius: 10px;
  padding: 1rem;
  box-shadow: 0px 0px 30px 5px rgb(133, 133, 133);
  background: #bbb;
  max-height: 700px;
}


.product-image img {
  width: 100%;
  height: 350px; 
  object-fit: contain;
  object-position: center;
}

.product-info {
  padding: 1rem;
  text-align: center;
}

.addcartButton {
  background-color: #111;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  display: inline-block;
  margin-top: 10px;
  text-decoration: none;
  transition: background-color 0.2s;
}

.addcartButton:hover {
  background-color: #0056b3; /* Darker shade on hover */
}

.buy-btn {
  background-color: #111;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  display: inline-block;
  margin-top: 10px;
  text-decoration: none;
  transition: background-color 0.2s;
}

.buy-btn:hover {
  background-color: #454545;
}

.item-name {
  color: black;
  font-size: 1.2rem; 
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.cart-page {
  padding: 20px;
}

.cart-item {
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.remove-item-btn, .clear-cart-btn, .checkout-btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  margin-left: 10px;
  border-radius: 5px;
}

.remove-item-btn:hover, .clear-cart-btn:hover, .checkout-btn:hover {
  background-color: #0056b3;
}


.cart-actions {
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
}

/* New or updated styles */
.checkout-button-container {
  display: flex;
  justify-content: center; /* Center the checkout button horizontally */
  padding: 20px 0; /* Add some padding above and below the button */
}

.checkout-btn {
  background-color: #28a745; /* Green color for checkout button */
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px; /* Larger font size for better visibility */
  border-radius: 5px;
  transition: background-color 0.2s; /* Smooth transition for hover effect */
}

.checkout-btn:hover {
  background-color: #218838; /* Darker green on hover for better interaction feedback */
}


/* For medium devices (tablets, 768px and up) */
@media (max-width: 768px) {
  .card-shop {
    flex-basis: calc(50% - 2rem); /* Two items per row */
  }
  .container-shop {
    grid-template-columns: repeat(2, 1fr); /* Adjust to 2 columns for tablets */
  }
}

/* For small devices (phones, 600px and up) */
@media (max-width: 600px) {
  .card-shop {
    flex-basis: 100%; /* Adjust for better fit */
    padding: 1rem;
    margin: 0.5rem;
    height: auto; /* Let the height be content-driven */
  }

  .product-image {
    transform: translate(0, 0.5rem);
    padding-bottom: 10px;
    .itself {
      transform: translate(0, 0);
    }
  } 
  .container-shop {
    grid-template-columns: 1fr; /* Adjust to 1 column for phones */
  }
}


// Shop Modal Checkout
/* Modal Background */
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1050;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Modal Content */
.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Form Styles */
.modal-content form {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.modal-content form input,
.modal-content form button {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 16px;
}

/* Button Styles */
.modal-content form button {
  background-color: #007bff;
  color: white;
  cursor: pointer;
  transition: background-color 0.2s;
}

.modal-content form button:hover {
  background-color: #0056b3;
}

.modal-content form .cancel-btn {
  background-color: #6c757d;
}

.modal-content form .cancel-btn:hover {
  background-color: #545b62;
}


.cookiesContent {
  width: 420px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $black_col;
  color: white;
  text-align: center;
  border-radius: 20px;
  padding: 30px 30px;
  button.close {
    width: 30px;
    font-size: 20px;
    color: #c0c5cb;
    align-self: flex-end;
    background-color: transparent;
    border: none;
    margin-bottom: 10px;
  }
  img {
    width: 82px;
    margin-bottom: 15px;
  }
  p {
    margin-bottom: 40px;
    font-size: 18px;
  }
  button.accept {
    background-color: #ed6755;
    border: none;
    border-radius: 5px;
    width: 200px;
    padding: 14px;
    font-size: 16px;
    color: white;
    box-shadow: 0px 6px 18px -5px rgba(237, 103, 85, 1);
  }
}


/* Container for the buttons */
.item-quantity-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100px; /* Adjust based on your layout */
}

/* General button styling */
.item-quantity-controls button {
  background-color: #007bff; /* Primary color */
  border: none;
  color: white;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Hover effects */
.item-quantity-controls button:hover {
  background-color: #0056b3; /* A darker shade of the primary color for hover state */
}

/* Section container styling to center the box */
#ticket {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
  padding: 20px;
  background-color: $black_more
}

.ticket {
  width: 90%;
  max-width: 600px;
  padding: 20px;
  margin: auto;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ticket iframe {
  width: 100%;
  border-radius: 8px;
}


.popupwindow {
  width: 100%;
  max-width: 600px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 5px;
  margin: 15px auto;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.popupwindow iframe {
  width: 100%;
  border-radius: 8px;
}
.donation-section {
  background-color: #1a1a1a;
  padding: 50px 0;
}

.donation-box {
  background-color: #333333;
  padding: 30px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  text-align: center;
  color: #ffffff;
}

.donation-options {
  margin-bottom: 20px;
}

.donation-options button {
  margin: 5px;
  padding: 10px 20px;
  background-color: #555555;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: #ffffff;
}

.add-to-cart-btn {
  background-color: #ff6600;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.checkbox input[type="checkbox"] {
  margin-right: 5px;
}

.top_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 75vh;
  top: 0;
  left: 0;
  z-index: 999;
}


.volunteer-container {
  --font-family-primary: 'Vollkorn', serif;
  --font-family-secondary: 'PT Sans', sans-serif;
  
  --font-size-title: 32px;
  --line-height-title: 1.4;
  --font-size-caption: 14px;
  --line-height-caption: 1.2;
  
  --color-text: #000000;
  --color-highlight-primary: #c9b06b;
  --color-highlight-secondary: #ffd47e;
  --border-radius-primary: 32px; 

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  @mixin mediaBig {
    @media (min-width: 576px) {
      @content;
    }
  }

  @include mediaBig {
    --font-size-title: 40px;
  }  

  .card {
    max-width: 960px;
    background-color: #fff;
    border-radius: var(--border-radius-primary);
    box-shadow: 24px 24px 80px rgba(0,0,0,0.1);
    padding: 20px 20px 28px 20px;
    box-sizing: border-box;
    margin: 20px;
    display: flex;
    flex-direction: column;
    
    @include mediaBig {
      flex-direction: row;
      align-items: center;
      margin: 40px;
      padding: 32px;
    }
  }

  .card__image {
    width: 100%;
    max-height: 300px;
    border-radius: var(--border-radius-primary);
    object-fit: cover;
    margin-bottom: 18px;
    
    @include mediaBig {
      width: 45%;
      max-height: none;
      min-height: 400px;
      margin-bottom: 0;
    }
  }

  .card__content {
    @include mediaBig {
      width: 55%;
      padding-left: 40px;
    }
  }

  .card__date {
    display: block;
    font-family: var(--font-family-secondary);
    font-size: 17px;
    line-height: var(--line-height-caption);
    color: var(--color-text);
    margin-bottom: 6px;
    
    @include mediaBig {
      margin-bottom: 8px;
    }
  }

  .card__title {
    font-family: var(--font-family-primary);
    font-size: var(--font-size-title);
    line-height: var(--line-height-title);
    color: var(--color-text);
    box-decoration-break: clone;
    background-image: linear-gradient(90deg, var(--color-highlight-primary), var(--color-highlight-secondary));
    background-size: 100% 42%;
    background-repeat: no-repeat;
    background-position: 0 85%;
    padding: 0 4px;
    margin-left: -4px;
  }
}
